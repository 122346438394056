body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #16171b;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  font-size: calc(15px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-logo {
  height: 50px;
  margin: 5px;
}

.status-div {
  background-color: #333333;
  color: rgb(98, 98, 98);
  padding: 10px;
}

.status-off {
  color: rgb(136, 136, 136);
}

.status-on {
  color: rgb(255, 145, 0);
  font-weight: bold;
}

.footer {
  margin-top: 15px;
}

.players-div {
  background-color: #282525;
  color: rgb(255, 255, 255);
  padding: 10px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.player-div {
  background-color: #217faa;
  color: rgb(255, 255, 255);
  padding: 4px;
  height: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 2px;
  border-radius: 4px;
  margin-left: 4px;
  margin-right: 4px;
}

.player-div-you {
  background-color: #b66128;
  color: rgb(255, 255, 255);
  padding: 4px;
  height: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 2px;
  border-radius: 4px;
  margin-left: 4px;
  margin-right: 4px;
}

button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  margin: 5px;
}

button:hover {
  background-color: #258628;
  cursor: pointer;
}

button:active {
  background-color: #1b671e;
  transform: translateY(1px);
}

.button-enabled {
  border: none;
}

.button-disabled {
  background-color: #575757;
  color: #717171;
}

.button-disabled:hover {
  background-color: #575757;
  cursor: default;
}

.button-disabled:active {
  background-color: #575757;
  transform: translateY(0px);
}

.admin-button {
  background-color: #5e1d6b;
}

.admin-button:hover {
  background-color: #51175d;
}

.admin-button:active {
  background-color: #4a1654;
  transform: translateY(1px);
}

input {
  border-radius: 4px;
  margin: 7px;
  background-color: #4b4a4a;
  height: 31px;
  width: 70%;
  max-width: 540px;
  box-sizing: border-box;
  font-size: 16px;
  border: none;
  color: #eeeeee;
  border: 1px solid #212020;
}

input:focus {
  outline: none !important;
  border:1px solid rgb(94, 94, 94);
}

.name {
  width: 360px;
}

.instructions {
  width: 80%;
  min-width: 356px;
  text-justify: top;
}

.instructions-text {
  margin-bottom: 6px;
  color: rgb(255, 145, 0);
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.instructions-div {
  margin-top: 20px;
}

.player-instructions {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  max-width: 540px;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #b6b6b6;
  text-align: left;
}

.parent {
  text-align: center;
}

.parent > ul {
  display: inline-block;
}

.section{
  margin-top: 12px;
  margin-bottom: 12px;
} 

.facilitator {
  background-color: #282525;
  color: rgb(255, 255, 255);
  padding: 1px;
}

.facilitator-text {
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 90%;
  max-width: 540px;
}

.img-player-on {
  height: 20px;
  width: 20px;
  padding-left: 3px;
  padding-right: 3px;
  vertical-align: top;
}

.img-player-off {
  height: 20px;
  width: 20px;
  padding-left: 3px;
  padding-right: 3px;
  opacity: 0.06;
  vertical-align: top;
}

.results {
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
}

table, th, td {
  border: 1px solid rgb(68, 51, 63);
  border-collapse: collapse;
}

th, td {
  background-color: rgb(50, 36, 36);
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
}
 
tr:nth-child(6n-1) td {
  background-color:rgb(51, 36, 75);
}

tr:nth-child(6n) td {
  background-color:rgb(51, 36, 75);
}

tr:nth-child(6n+1) td {
  background-color:rgb(51, 36, 75);
}

th {
  background-color: rgb(125, 70, 26);
}

.statement label {
  display: inline-block;
  width: 60px;
  text-align: right;
}

.guess {
  margin-left: auto;
  margin-right: auto;
}

.guess input {
  display:inline-block;
  width: 25px;
  vertical-align: top;
}

.guess label {
  display:inline-block;
  text-align: left;
  width: 80%;
  padding-top: 7px;
}

.guess-area {
  padding:20px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgb(31, 31, 31);
  border-radius: 5px;
  max-width: 80%;
  min-width: 300px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.summary {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.summary-item {
  margin-top: 6px;
  margin-bottom: 6px;
  text-align: left;
}

.summary-ul {
  margin-left: auto;
  margin-right: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-left: 18px;
}

.statement-area {
  padding:20px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgb(31, 31, 31);
  border-radius: 5px;
}

.error {
  padding:10px;
}

.error-img {
  height: 32px;
  width: 32px;
  padding-right: 4px;
  vertical-align: middle;
}

.facilitator-info {
  color: rgb(18, 156, 62);
  font-style: italic;
}

li {
  text-align:center;
}

ul {
  list-style-type: none;
}
